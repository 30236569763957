body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSelect-select {
  border: none
}

.MuiButton-outlined{
  text-transform: none !important;
}

.MuiButton-text {
  text-transform: none !important;
}

@media (max-width: 900px) {
  .introduction {
    padding-left: 30px !important;
  }

  .introduction-drawer {
    display: none;
  }
}
